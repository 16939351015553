<template>
  <div
    @dragover.prevent
    @drop="dropItem"
    class="d-flex flex-column p-2"
  >
    <div class="d-flex mb-3">
      <div class="flex-grow-1">
        <div
          :class="{ out: !day.isCurrentMonth, today: isToday }"
          class="small date"
        >{{ label }}</div>
      </div>

      <Tooltip :content="'Create a new session'" :placement="'bottom'">
        <IconButton
          @click="createSession"
          :icon="'plus'"
          class="btn btn-circle"
        ></IconButton>
      </Tooltip>

      <BootstrapDropdown>
        <li>
          <button
            @click="$emit('findProgram')"
            role="button"
            class="dropdown-item small"
          >Find a program</button>
        </li>
        <li>
          <button
            @click="pasteSessions"
            :disabled="!sessionClipboardItems.length"
            role="button"
            class="dropdown-item small"
          >Paste session(s)</button>
        </li>
      </BootstrapDropdown>
    </div>

    <CalendarSessionItem
      v-for="session in sessionsToday"
      :key="session.sessionId"
      :session="session"
      class="my-2"
    ></CalendarSessionItem>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapState } from 'vuex';

export default {
  name: 'CalendarMonthDayItem',
  components: {
    CalendarSessionItem: () => import('@/components/calendar/CalendarSessionItem'),
    Tooltip: () => import('@/components/Tooltip'),
    IconButton: () => import('@/components/button/IconButton'),
    BootstrapDropdown: () => import('@/components/bootstrap/BootstrapDropdown'),
  },
  props: {
    day: {
      type: Object,
      required: true,
    },
    isToday: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('calendar', ['calendarSessions']),
    ...mapState('session', ['sessionClipboardItems']),
    sessionsToday() {
      return this.calendarSessions.filter((s) => dayjs(s.date).format('YYYY-MM-DD') === this.day.date);
    },
    label() {
      return dayjs(this.day.date).format('D');
    },
  },
  methods: {
    async createSession() {
      try {
        this.$store.dispatch('calendar/setBusy', true);
        await this.$store.dispatch('calendar/createSession', this.day.date);
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.$store.dispatch('calendar/setBusy', false);
      }
    },
    async pasteSessions() {
      try {
        const { date } = this.day;
        this.$store.dispatch('calendar/setBusy', true);
        await this.$store.dispatch('calendar/pasteSessions', {
          sessions: this.sessionClipboardItems,
          date,
        });
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.$store.dispatch('calendar/setBusy', false);
      }
    },
    async dropItem(e) {
      const { date } = this.day;
      const { dataTransfer } = e;
      const sessionId = dataTransfer.getData('sessionId');
      const sessionDate = dataTransfer.getData('sessionDate');
      if (sessionDate !== date) {
        try {
          this.$store.dispatch('calendar/setBusy', true);
          await this.$store.dispatch('calendar/moveSession', { sessionId: parseInt(sessionId, 10), date });
        } catch (err) {
          this.$store.dispatch('addSystemError', err, { root: true });
        } finally {
          this.$store.dispatch('calendar/setBusy', false);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars.scss';

.date {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 75%;
  text-align: center;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, .05);

  &.today {
    background-color: $primary !important;
    color: white;
  }
}

.out {
  opacity: .25;
}
</style>
